'use strict';

import defaultState from './account.state';
import { getAccount, deleteAccount } from '@/api/account';
import { renewOwnApplicantProfile } from '@/api/applicant';
import router from '@/router';
import Store from '@/store';

const actions = {
  updateProfile ({ commit, dispatch }) {
    getAccount()
      .then(function ({ data: accountData }) {
        commit('UPDATE_USER', accountData);
      })
      .catch(function () {
        dispatch('logoutIfAuthenticated');
      })
    ;
  },
  deleteProfile ({ getters, commit, dispatch }) {
    const accountType = getters.activeAccount.type;
    deleteAccount()
      .then(function () {
        router.push({ name: 'account-deleted', params: { accountType: accountType } });
      })
    ;
  },
  renewAccount ({ commit, dispatch }) {
    renewOwnApplicantProfile()
      .then(function ({ data: response }) {
        commit('UPDATE_USER', response);
      })
    ;
  },
};

const mutations = {
  UPDATE_USER (state, userData) {
    if (undefined === userData) {
      userData = defaultState.current;
    }
    state.current = userData;
  },
};

const getters = {
  activeAccount: function (state) {
    return state.current ? state.current : defaultState.current;
  },
  isCompanyAccount: function (state) {
    const current = getters.activeAccount(state);
    return current.type === 'company';
  },
  isApplicantAccount: function (state) {
    const current = getters.activeAccount(state);
    return current.type === 'applicant';
  },
  isAdminAccount: function (state) {
    const current = getters.activeAccount(state);
    return current.type === 'admin';
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
