'use strict';

import state from './search.state';

const actions = {
  unsetSearchStorageEntry (context, key) {
    context.commit('UNSET_SEARCH_ENTRY', key);
  },
  updateSearchStorageEntry (context, payload) {
    context.commit('SET_SEARCH_ENTRY', payload);
  },
  setSearchScrollToEntry (context, { key, recordId }) {
    let data = {};
    if (context.state.searchData[key]) {
      data = Object.assign({}, context.state.searchData[key]);
    }
    data.recordId = recordId;
    context.commit('SET_SEARCH_ENTRY', { key: key, data: data });
  },
  resetSearchStorage (context) {
    context.commit('RESET_SEARCH');
  },
};

const mutations = {
  SET_SEARCH_ENTRY (state, payload) {
    state.searchData[payload.key] = payload.data;
  },
  UNSET_SEARCH_ENTRY (state, key) {
    state.searchData[key] = null;
  },
  RESET_SEARCH (state) {
    Object.keys(state.searchData).forEach(function (key) {
      state.searchData[key] = null;
    });
  },
};

const getters = {
  getSearchStorageEntryByKey: (state) => (key) => {
    if (Object.prototype.hasOwnProperty.call(state.searchData, key)) {
      return state.searchData[key];
    }
    return null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
