<template>
  <b-navbar-nav class="topbar-menu-container" v-if="this.isAuthenticated">
    <ba-navbar-menu :message-count="messageCount"/>
    <b-nav-item key="logout" @click="logout()">
      <span>{{ $t('action_logout') }}</span>
    </b-nav-item>
  </b-navbar-nav>
  <b-navbar-nav class="topbar-menu-container" v-else>
    <b-nav-dropdown right variant="text" :no-caret="true" ref="login-dropdown">
      <template v-slot:button-content>{{ $t('action_login') }}</template>
      <ba-login-form id="nav-login-form-desktop" @close="closeLoginDropdown"/>
    </b-nav-dropdown>
    <b-nav-item key="register" :to="{ name: 'register' }">{{ $t('action_register') }}</b-nav-item>
  </b-navbar-nav>
</template>

<script>
  import BaLoginForm from '@/components/partials/Header/BaLoginForm';
  import BaNavbarMenu from '@/components/partials/Header/BaNavbarMenu';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    components: {
      BaLoginForm,
      BaNavbarMenu,
    },
    props: {
      messageCount: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'activeAccount']),
    },
    methods: {
      ...mapActions(['logout']),
      closeLoginDropdown: function () {
        this.$refs['login-dropdown'].visible = false;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .topbar-menu-container {
    display: none;
    max-width: 70%;

    .dropdown-menu-right {
      margin-top: 0;
    }

    > .nav-item > .nav-link {
      font-size: $ba-font-size-sm;
      letter-spacing: .9px;
      text-transform: uppercase;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: right;
      // compensate padding of last nav item
      margin-right: -.5rem;
      > .nav-item {
        margin-left: 21px;
        > .nav-link {
          padding-left: 9px;
          padding-right: 9px;
          padding-bottom: 9px;
          line-height: 21px;
          @include hover-focus {
            background: $ba-light-primary;
          }
        }
        &.show {
          > .nav-link {
            background: $ba-light-primary;
          }
        }
      }
    }
  }
  .b-nav-dropdown {
    text-align: center;
    min-width: 10rem;
  }
</style>

<i18n lang="yaml" locale="de">
  action_logout: 'Logout'
  action_login: 'Login'
  action_register: 'Registrierung'
</i18n>
