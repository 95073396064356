var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-menu" }, [
    _c(
      "div",
      { staticClass: "container nav-container" },
      [
        _c("div", { staticClass: "user-info" }, [
          _c(
            "div",
            { staticClass: "user-icon" },
            [
              _c("font-awesome-icon", {
                attrs: { size: "2x", icon: "building" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-data" },
            [
              _c("h5", [
                _vm._v(
                  _vm._s(_vm.activeAccount.firstName) +
                    " " +
                    _vm._s(_vm.activeAccount.lastName)
                ),
              ]),
              _vm._v(" "),
              _vm.isCompanyAccount
                ? _c("div", [_vm._v(_vm._s(_vm.activeAccount.displayName))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-link",
                {
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.header.nav_mobile.action_logout"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "b-navbar-nav",
          { staticClass: "user-links" },
          [
            _c(
              "b-nav-item",
              { attrs: { to: { name: "dashboard" } } },
              [
                _c("font-awesome-icon", { attrs: { icon: "tachometer-alt" } }),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("components.partials.header.nav_mobile.dashboard")
                    ) +
                    "\n      "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("b-nav-item", { attrs: { to: { name: "messages" } } }, [
              _vm.messageCount
                ? _c(
                    "span",
                    {
                      staticClass: "icon-wrap has-new-messages",
                      attrs: {
                        title: _vm.$tc(
                          "user.menu.new_messages",
                          _vm.messageCount
                        ),
                      },
                    },
                    [_c("font-awesome-icon", { attrs: { icon: "envelope" } })],
                    1
                  )
                : _c(
                    "span",
                    { staticClass: "icon-wrap" },
                    [_c("font-awesome-icon", { attrs: { icon: "envelope" } })],
                    1
                  ),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("components.partials.header.nav_mobile.messages")
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _vm.isApplicantAccount
              ? _c("b-nav-item", { attrs: { to: { name: "profile" } } }, [
                  _c(
                    "span",
                    { staticClass: "icon-wrap" },
                    [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.partials.header.nav_mobile.profile_applicant"
                        )
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isCompanyAccount &&
            _vm.isGranted(_vm.permissions.COMPANY_EDIT_PROFILE)
              ? _c(
                  "b-nav-item",
                  { attrs: { to: { name: "owncompanyprofile" } } },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [_c("font-awesome-icon", { attrs: { icon: "user" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.partials.header.nav_mobile.profile_company"
                          )
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isApplicantAccount
              ? _c(
                  "b-nav-item",
                  { attrs: { to: { name: "companybookmarks" } } },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "bookmark" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "components.partials.header.nav_mobile.bookmarks_applicant"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm.isCompanyAccount
              ? _c(
                  "b-nav-item",
                  { attrs: { to: { name: "applicantbookmarks" } } },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "bookmark" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "components.partials.header.nav_mobile.bookmarks_company"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isCompanyAccount
              ? _c(
                  "b-nav-item",
                  { attrs: { to: { name: "applicantsearchprofiles" } } },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "components.partials.header.nav_mobile.search_profiles"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm.isApplicantAccount
              ? _c(
                  "b-nav-item",
                  { attrs: { to: { name: "companysearchprofiles" } } },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrap" },
                      [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "components.partials.header.nav_mobile.search_profiles"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("b-nav-item", { attrs: { to: { name: "account" } } }, [
              _c(
                "span",
                { staticClass: "icon-wrap" },
                [_c("font-awesome-icon", { attrs: { icon: "address-card" } })],
                1
              ),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("components.partials.header.nav_mobile.account")
                  ) +
                  "\n      "
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }