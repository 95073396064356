var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.isAuthenticated
    ? _c(
        "b-navbar-nav",
        { staticClass: "topbar-menu-container" },
        [
          _c("ba-navbar-menu", {
            attrs: { "message-count": _vm.messageCount },
          }),
          _vm._v(" "),
          _c(
            "b-nav-item",
            {
              key: "logout",
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("action_logout")))])]
          ),
        ],
        1
      )
    : _c(
        "b-navbar-nav",
        { staticClass: "topbar-menu-container" },
        [
          _c(
            "b-nav-dropdown",
            {
              ref: "login-dropdown",
              attrs: { right: "", variant: "text", "no-caret": true },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.$t("action_login")))]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("ba-login-form", {
                attrs: { id: "nav-login-form-desktop" },
                on: { close: _vm.closeLoginDropdown },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { key: "register", attrs: { to: { name: "register" } } },
            [_vm._v(_vm._s(_vm.$t("action_register")))]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }