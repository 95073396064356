import { render, staticRenderFns } from "./BaFooter.vue?vue&type=template&id=4ff775ea"
import script from "./BaFooter.vue?vue&type=script&lang=js"
export * from "./BaFooter.vue?vue&type=script&lang=js"
import style0 from "./BaFooter.vue?vue&type=style&index=0&id=4ff775ea&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/translations/common.i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fgerthammes%2Fdev%2Fcampus-match%2Fdhbw-ravensburg_campus-match-frontend%2Fsrc%2Fcomponents%2FBaFooter.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/gerthammes/dev/campus-match/dhbw-ravensburg_campus-match-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ff775ea')) {
      api.createRecord('4ff775ea', component.options)
    } else {
      api.reload('4ff775ea', component.options)
    }
    module.hot.accept("./BaFooter.vue?vue&type=template&id=4ff775ea", function () {
      api.rerender('4ff775ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BaFooter.vue"
export default component.exports