var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-input-group",
    { staticClass: "ba-group-password" },
    [
      _c(
        "b-form-input",
        _vm._b(
          {
            attrs: { type: _vm.inputType, value: this.value },
            on: { input: _vm.handleInput },
          },
          "b-form-input",
          _vm.inputProperties,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "b-input-group-append",
        { on: { click: _vm.toggle } },
        [
          _c("font-awesome-icon", {
            attrs: { color: "#b0b0b0", icon: _vm.hidden ? "eye" : "eye-slash" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }